import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.onupdate');
import { OnInit } from '@angular/core';

@Component({
    selector: 'wiz-component-onupdate',
template: templateSource || '',
    styles: [`

/* file: /opt/service/portal/branch/main/build/src/app/component.onupdate/view.scss */
.content {
  position: relative;
  width: 100%;
  height: 100%;
}

h1 {
  font-weight: 100;
  font-size: 27pt;
  color: var(--wc-red);
}

p {
  font-weight: 300;
}

.warning-content {
  position: absolute;
  top: 25%;
  left: 0;
  width: 100%;
  height: 300px;
  text-align: center;
  margin: 0;
}`],
})
export class ComponentOnupdateComponent implements OnInit {
    public async ngOnInit() { }
}

export default ComponentOnupdateComponent;