import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.mydesk.worklog');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-mydesk-worklog',
template: templateSource || '',
    styles: [`

/* file: /opt/service/portal/branch/main/build/src/app/page.mydesk.worklog/view.scss */
.tab-content-header {
  max-width: 100%;
  padding: 16px 32px;
  background: #fff;
}

.tab-content-footer {
  padding: 12px;
  background: #fff;
  border-top: 1px solid var(--wc-border);
}

li.nav-item {
  cursor: pointer;
}

.page-icon {
  width: 48px;
  height: 48px;
  border-radius: 12px;
  background: var(--wc-light-3);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}
.page-icon.page-btn {
  cursor: pointer;
  background: var(--wc-blue);
}
.page-icon.page-btn:hover {
  background: var(--wc-blue-hover);
}

.main-area {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.main-area .tab-content-body {
  flex: 1;
  overflow: auto;
}

.worklog-table {
  width: 100%;
}
.worklog-table td {
  border: 1px solid var(--wc-border);
  padding: 8px 12px;
}
.worklog-table td.day {
  width: 140px;
  text-align: center;
}
.worklog-table td.time {
  width: 80px;
  text-align: right;
}
.worklog-table td:first-child {
  border-left: none;
}
.worklog-table td:last-child {
  border-right: none;
}
.worklog-table tr:last-child td {
  border-bottom: none;
}
.worklog-table tr:hover {
  background: var(--wc-light);
}
.worklog-table th {
  border: 1px solid var(--wc-border);
  border-top: none;
  text-align: center;
  padding: 4px 0;
  background: var(--wc-light);
}
.worklog-table th:first-child {
  border-left: none;
  border-top-left-radius: 16px;
}
.worklog-table th:last-child {
  border-right: none;
  border-top-right-radius: 16px;
}`],
})
export class PageMydeskWorklogComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public months: any = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    public logs: any = [];
    public timelines: any = [];
    public hours: any = [];

    public year: any = new Date().getFullYear();
    public month: any = new Date().getMonth() + 1;

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/auth/login");

        for (let i = 8; i < 22; i += 0.5)
            this.timelines.push(i);
        for (let i = 8; i < 22; i++)
            this.hours.push(i);

        await this.load(this.year, this.month);
    }

    public async load(year: number, month: number) {
        this.year = year;
        this.month = month;
        const { data } = await wiz.call("get", { year: year, month: month });
        this.logs = data;
        await this.service.render();
    }
}

export default PageMydeskWorklogComponent;