import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.widget.eform.approval');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-widget-eform-approval',
template: templateSource || '',
    styles: [`

/* file: /opt/service/portal/branch/main/build/src/app/portal.widget.eform.approval/view.scss */
.card {
  width: 100%;
  height: 326px;
  overflow: hidden;
}

table th,
table td {
  white-space: nowrap;
  padding: 6px 12px;
}

*::-webkit-scrollbar-track {
  background-color: transparent;
}

*::-webkit-scrollbar {
  height: 6px;
  width: 6px;
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  border-radius: 0;
  background-color: var(--wc-blue-light-3);
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent);
}`],
})
export class PortalWidgetEformApprovalComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public action: string = 'ready';
    public loaded: boolean = false;
    public page: number = 1;
    public startpage: number = 1;
    public lastpage: number = 1;
    public data: any = [];

    public categorymap: any = {
        HR: '인사',
        GA: '총무',
        FA: '재무',
        WR: '업무'
    }

    public statusmap: any = {
        draft: '작성중',
        process: '결재중',
        accept: '승인됨',
        reject: '반려됨',
        cancel: '회수됨'
    };

    public async ngOnInit() {
        await this.service.init();
        await this.load();
    }

    public async load(page: number = 1, action: any = null) {
        if (!action) action = this.action;
        this.action = action;
        this.loaded = false;
        await this.service.render();

        const { code, data } = await wiz.call("docs", { page, action });
        if (code != 200 || !data) return;

        const { docs, lastpage } = data;
        const startpage = Math.floor((page - 1) / 10) * 10 + 1;

        this.page = page;
        this.lastpage = lastpage * 1;
        this.startpage = startpage * 1;
        this.data = docs;

        this.loaded = true;
        await this.service.render();
    }

    public async pageMove(page: number) {
        await this.load(page);
    }
}

export default PortalWidgetEformApprovalComponent;