import '@angular/compiler';
import { ComponentNavUserComponent } from './component.nav.user/component.nav.user.component';
import { PageAdminEformComponent } from './page.admin.eform/page.admin.eform.component';
import { PortalWidgetCardComponent } from './portal.widget.card/portal.widget.card.component';
import { LayoutLayoutAsideFullComponent } from './layout.layout.aside.full/layout.layout.aside.full.component';
import { PortalSeasonLoadingHexaComponent } from './portal.season.loading.hexa/portal.season.loading.hexa.component';
import { PortalEformHrVacationAfterComponent } from './portal.eform.hr.vacation.after/portal.eform.hr.vacation.after.component';
import { ComponentOnupdateComponent } from './component.onupdate/component.onupdate.component';
import { ComponentNavAdminComponent } from './component.nav.admin/component.nav.admin.component';
import { PortalSeasonPagenationComponent } from './portal.season.pagenation/portal.season.pagenation.component';
import { PageAdiminUserComponent } from './page.adimin.user/page.adimin.user.component';
import { PageMydeskMemoComponent } from './page.mydesk.memo/page.mydesk.memo.component';
import { PageMydeskMypageComponent } from './page.mydesk.mypage/page.mydesk.mypage.component';
import { LayoutEmptyComponent } from './layout.empty/layout.empty.component';
import { PortalWidgetChartServerGpuComponent } from './portal.widget.chart.server.gpu/portal.widget.chart.server.gpu.component';
import { PageAdminEformViewerComponent } from './page.admin.eform.viewer/page.admin.eform.viewer.component';
import { PortalEformHrVacationSickComponent } from './portal.eform.hr.vacation.sick/portal.eform.hr.vacation.sick.component';
import { PageContactComponent } from './page.contact/page.contact.component';
import { PageAuthenticateComponent } from './page.authenticate/page.authenticate.component';
import { PageEformMainComponent } from './page.eform.main/page.eform.main.component';
import { PageEformDocsComponent } from './page.eform.docs/page.eform.docs.component';
import { PageAdminDashboardComponent } from './page.admin.dashboard/page.admin.dashboard.component';
import { PortalWidgetEformApprovalComponent } from './portal.widget.eform.approval/portal.widget.eform.approval.component';
import { PageEformApprovalItemComponent } from './page.eform.approval.item/page.eform.approval.item.component';
import { PortalSeasonStatusbarComponent } from './portal.season.statusbar/portal.season.statusbar.component';
import { PortalSeasonAlertComponent } from './portal.season.alert/portal.season.alert.component';
import { PortalEformHrVacationPublicComponent } from './portal.eform.hr.vacation.public/portal.eform.hr.vacation.public.component';
import { ComponentQuickmenuComponent } from './component.quickmenu/component.quickmenu.component';
import { PortalEformHrBusinesstripInternalComponent } from './portal.eform.hr.businesstrip.internal/portal.eform.hr.businesstrip.internal.component';
import { PortalUtilMemoComponent } from './portal.util.memo/portal.util.memo.component';
import { PortalSeasonUiDropdownComponent } from './portal.season.ui.dropdown/portal.season.ui.dropdown.component';
import { ComponentEformViewerComponent } from './component.eform.viewer/component.eform.viewer.component';
import { PageAdminUserComponent } from './page.admin.user/page.admin.user.component';
import { PortalSeasonTabComponent } from './portal.season.tab/portal.season.tab.component';
import { PortalEformHrVacationUnpaidComponent } from './portal.eform.hr.vacation.unpaid/portal.eform.hr.vacation.unpaid.component';
import { PortalEformHrEventCancelComponent } from './portal.eform.hr.event.cancel/portal.eform.hr.event.cancel.component';
import { PageDashboardComponent } from './page.dashboard/page.dashboard.component';
import { PageEformDocsItemComponent } from './page.eform.docs.item/page.eform.docs.item.component';
import { PortalWidgetChartServerEnvComponent } from './portal.widget.chart.server.env/portal.widget.chart.server.env.component';
import { PortalSeasonLoadingSeasonComponent } from './portal.season.loading.season/portal.season.loading.season.component';
import { PageEformApprovalComponent } from './page.eform.approval/page.eform.approval.component';
import { PortalSeasonViewerTreeComponent } from './portal.season.viewer.tree/portal.season.viewer.tree.component';
import { PortalWidgetEformDocsComponent } from './portal.widget.eform.docs/portal.widget.eform.docs.component';
import { PortalSeasonLoadingDefaultComponent } from './portal.season.loading.default/portal.season.loading.default.component';
import { PageMydeskCalendarComponent } from './page.mydesk.calendar/page.mydesk.calendar.component';
import { PortalEformRendererComponent } from './portal.eform.renderer/portal.eform.renderer.component';
import { PortalEformHrVacationAnnualComponent } from './portal.eform.hr.vacation.annual/portal.eform.hr.vacation.annual.component';
import { LayoutAsideComponent } from './layout.aside/layout.aside.component';
import { PageMydeskWorklogComponent } from './page.mydesk.worklog/page.mydesk.worklog.component';

const INDEX_PAGE = "dashboard";

import { URLPattern } from "urlpattern-polyfill";
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

let app_routes: Routes = [
    {
        component: LayoutLayoutAsideFullComponent,
        "children": [
            {
                "path": "admin/eform/forms",
                component: PageAdminEformComponent,
                "app_id": "page.admin.eform"
            },
            {
                "path": "admin/info/user",
                component: PageAdiminUserComponent,
                "app_id": "page.adimin.user"
            },
            {
                "path": "admin/dashboard",
                component: PageAdminDashboardComponent,
                "app_id": "page.admin.dashboard"
            },
            {
                "path": "admin/user",
                component: PageAdminUserComponent,
                "app_id": "page.admin.user"
            }
        ]
    },
    {
        component: LayoutAsideComponent,
        "children": [
            {
                "path": "mydesk/memo/:id",
                component: PageMydeskMemoComponent,
                "app_id": "page.mydesk.memo"
            },
            {
                "path": "mydesk/mypage",
                component: PageMydeskMypageComponent,
                "app_id": "page.mydesk.mypage"
            },
            {
                "path": "contact",
                component: PageContactComponent,
                "app_id": "page.contact"
            },
            {
                "path": "eform/main",
                component: PageEformMainComponent,
                "app_id": "page.eform.main"
            },
            {
                "path": "eform/docs",
                component: PageEformDocsComponent,
                "app_id": "page.eform.docs"
            },
            {
                "path": "eform/approval/:id",
                component: PageEformApprovalItemComponent,
                "app_id": "page.eform.approval.item"
            },
            {
                "path": "dashboard",
                component: PageDashboardComponent,
                "app_id": "page.dashboard"
            },
            {
                "path": "eform/docs/:id",
                component: PageEformDocsItemComponent,
                "app_id": "page.eform.docs.item"
            },
            {
                "path": "eform/approval",
                component: PageEformApprovalComponent,
                "app_id": "page.eform.approval"
            },
            {
                "path": "mydesk/calendar",
                component: PageMydeskCalendarComponent,
                "app_id": "page.mydesk.calendar"
            },
            {
                "path": "mydesk/worklog",
                component: PageMydeskWorklogComponent,
                "app_id": "page.mydesk.worklog"
            }
        ]
    },
    {
        component: LayoutEmptyComponent,
        "children": [
            {
                "path": "admin/eform/viewer/:id",
                component: PageAdminEformViewerComponent,
                "app_id": "page.admin.eform.viewer"
            },
            {
                "path": "authenticate",
                component: PageAuthenticateComponent,
                "app_id": "page.authenticate"
            }
        ]
    }
];

export class RouteInfo {
    public path: any = "";
    public segment: any = {};

    constructor() { }
}

window.WizRoute = new RouteInfo();

let patternMatcher = (pattern: any, url: any) => {
    let urlpath = url.map((x: any) => x.path).join("/");
    let testurl = 'http://test/';
    pattern = '/' + pattern;
    urlpath = testurl + urlpath;
    pattern = new URLPattern({ pathname: pattern });
    pattern = pattern.exec(urlpath)
    if (pattern && pattern.pathname) {
        let posParams = {};
        for (let key in pattern.pathname.groups) {
            if (pattern.pathname.groups[key]) {
                posParams[key] = pattern.pathname.groups[key];
            }
        }
        window.WizRoute.path = url.map((x: any) => x.path).join("/");
        window.WizRoute.segment = posParams;

        return { consumed: url, posParams: posParams };
    }
    return null
}

let routes: Routes = [{
    matcher: (url: any) => {
        for (let i = 0; i < app_routes.length; i++) {
            let layout = app_routes[i];
            let layout_childs = layout.children;
            for (let j = 0; j < layout_childs.length; j++) {
                let child = layout_childs[j];
                let matcher = patternMatcher(child.path, url);
                if (matcher)
                    return null;
            }
        }
        return { consumed: url, posParams: {} };
    },
    redirectTo: INDEX_PAGE
}];

for (let i = 0; i < app_routes.length; i++) {
    let layout = app_routes[i];
    let layout_component = layout.component;
    let layout_childs = layout.children;

    let router: any = {
        path: '',
        component: layout_component,
        children: []
    };

    for (let j = 0; j < layout_childs.length; j++) {
        let child = layout_childs[j];
        router.children.push({
            matcher: (url: any) => {
                let matcher = patternMatcher(child.path, url);
                if (matcher) return matcher;
                return null;
            },
            component: child.component
        });
    }
    routes.push(router);
}

@NgModule({ imports: [RouterModule.forRoot(routes)], exports: [RouterModule] })
export class AppRoutingModule { }