import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.nav.admin');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { HostListener } from '@angular/core';

@Component({
    selector: 'wiz-component-nav-admin',
template: templateSource || '',
    styles: [`

/* file: /opt/service/portal/branch/main/build/src/app/component.nav.admin/view.scss */
.navbar-vertical {
  overflow: auto;
  position: relative;
  height: 100%;
  box-shadow: none;
  min-width: 260px;
  border-right: 1px solid var(--wc-border);
}
@media (max-width: 768px) {
  .navbar-vertical {
    border-bottom: 1px solid var(--wc-border);
    border-right: none;
  }
}

li.nav-item.active .nav-link {
  color: var(--wc-blue) !important;
  font-weight: 1000;
}
li.nav-item.active i {
  color: var(--wc-blue);
}

.navbar-brand-image {
  width: 160px;
  margin-top: 16px;
  margin-bottom: 12px;
}
@media (max-width: 768px) {
  .navbar-brand-image {
    width: 160px;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.user-profile {
  padding: 28px;
  padding-top: 0;
  padding-right: 0;
  padding-left: 0;
}
@media (max-width: 768px) {
  .user-profile {
    padding: 24px;
    padding-top: 0;
    padding-right: 0;
    padding-left: 0;
  }
}
.user-profile .card {
  box-shadow: none;
  border-right: none;
  border-left: none;
  border-radius: 0;
}
.user-profile .card .card-body {
  border-top: none !important;
}
.user-profile .card .card-footer {
  padding: 0;
  background-color: var(--wc-background);
  border-radius: 0;
}
.user-profile .clicks {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.user-profile .clicks a {
  display: block;
  width: 100%;
  color: #63667b;
  text-align: center;
}
.user-profile .clicks .click {
  cursor: pointer;
  padding: 0.75rem;
  display: inline-block;
}
.user-profile .clicks .click:hover {
  color: var(--wc-blue);
  background-color: var(--wc-blue-light);
}
.user-profile .clicks .line {
  margin: 0.75rem 0;
  border-right: 1px solid #63667b;
}
.user-profile .userinfo {
  padding: 0.25rem;
  text-align: left;
}
.user-profile .userinfo .name {
  font-family: "main-b";
  color: #2D333F;
  font-size: 20px;
}
.user-profile .userinfo .uclass,
.user-profile .userinfo .mail {
  font-size: 12px;
  color: #9097AD;
}

a {
  cursor: pointer;
}`],
})
export class ComponentNavAdminComponent implements OnInit {
    public interval_id: number = 0;

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.commuteStatus();
        this.interval_id = setInterval(async () => {
            await this.commuteStatus();
        }, 60 * 1000);
    }

    public async ngOnDestroy() {
        if (this.interval_id > 0)
            clearInterval(this.interval_id);
    }

    public commute: any = {
        status: null,
        worktime: 0
    }

    public async commuteStatus() {
        const { code, data } = await wiz.call("commute/status");
        if (code != 200) return;
        this.commute = data;
        await this.service.render();
    }

    public async commuteToggle() {
        if (this.commute.status == 'in') {
            let res = await this.service.alert.show({ title: '업무종료', message: '업무를 종료하시겠습니까?', cancel: false, action: '확인' });
            if (!res)
                return
        }

        await this.service.loading.show();
        let { data } = await wiz.call("commute/toggle");
        await this.commuteStatus();
        await this.service.loading.hide();
        if (!data)
            await this.service.alert.show({ title: 'Denied', message: '허용되지 않은 IP', cancel: false, action: '확인' });
    }
    @HostListener('document:click')
    public clickout() {
        this.service.navbar.toggle(true);
    }

    public isActive(link: string) {
        return location.pathname.indexOf(link) === 0
    }

}

export default ComponentNavAdminComponent;