import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.dashboard');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-dashboard',
template: templateSource || '',
    styles: [`

/* file: /opt/service/portal/branch/main/build/src/app/page.dashboard/view.scss */
.row {
  margin-top: 12px;
}
.row > * {
  display: block;
  margin: 0;
  padding: 12px;
}`],
})
export class PageDashboardComponent implements OnInit {
    public status: any = null;

    constructor(@Inject( Service) public service: Service) { }

    public gpus: any = [
        { hostname: 'gpu-1', device: 0 },
        { hostname: 'gpu-1', device: 1 },
        { hostname: 'gpu-nbpl', device: 0 },
        { dummy: true },
        { hostname: 'gpu-2', device: 0 },
        { hostname: 'gpu-2', device: 1 },
        { hostname: 'gpu-2', device: 2 },
        { hostname: 'gpu-2', device: 3 },
        { hostname: 'gpu-3', device: 0 },
        { hostname: 'gpu-3', device: 1 },
        { hostname: 'gpu-3', device: 2 },
        { hostname: 'gpu-3', device: 3 },
        { hostname: 'gpu-4', device: 0 },
        { hostname: 'gpu-4', device: 1 },
        { hostname: 'gpu-4', device: 2 },
        { hostname: 'gpu-4', device: 3 },
        { hostname: 'gpu-5', device: 0 },
        { hostname: 'gpu-5', device: 1 },
        { hostname: 'gpu-5', device: 2 },
        { hostname: 'gpu-5', device: 3 },
    ];

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/auth/login");
        await this.loadStatus();
        await this.checkToken();
    }

    public async loadStatus() {
        const { data } = await wiz.call("status");
        this.status = data;
        await this.service.render();
    }

    private async checkToken() {
        if (window.api && window.api.send) {
            window.api.send("login", this.service.auth.session);
        }
    }

    public async alert(message: string, status: string = 'error', cancel: any = false, action: string = '확인') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public vacation() {
        let self = this;
        let fn = async (force: any = false) => {
            let eformwiz = wiz.app("page.eform.main");
            const { code, data } = await eformwiz.call("create", { form: '1', force });
            if (code != 200) {
                if (code == 301) {
                    let res = await self.alert('작성중인 휴가가 있습니다.', 'warning', '새로작성', '이어서작성');
                    if (res === true) {
                        self.service.href("/eform/docs/" + data);
                    } else if (res === false) {
                        await fn(true);
                    }
                    return;
                }
                return;
            }
            self.service.href("/eform/docs/" + data);
        }

        return fn;
    }
}

export default PageDashboardComponent;