import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('layout.empty');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-layout-empty',
template: templateSource || '',
    styles: [`

/* file: /opt/service/portal/branch/main/build/src/app/layout.empty/view.scss */
.block-page {
  display: flex;
  align-items: center;
  justify-content: center;
}
.block-page svg path,
.block-page svg rect {
  fill: #FF6700;
}

.wiz-page {
  max-width: 100%;
}`],
})
export class LayoutEmptyComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
    }
}

export default LayoutEmptyComponent;