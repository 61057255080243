import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.eform.approval.item');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-eform-approval-item',
template: templateSource || '',
    styles: [`

/* file: /opt/service/portal/branch/main/build/src/app/page.eform.approval.item/view.scss */
/* src/app/page.eform.approval.item/view.scss: no such file or directory */`],
})
export class PageEformApprovalItemComponent implements OnInit {
    public doc_id: any;

    constructor(@Inject( Service) public service: Service) {
        this.doc_id = WizRoute.segment.id;
    }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/authenticate");
        await this.service.render();
    }
}

export default PageEformApprovalItemComponent;