import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.eform.hr.vacation.after');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import moment from 'moment';

@Component({
    selector: 'wiz-portal-eform-hr-vacation-after',
template: templateSource || '',
    styles: [``],
})
export class PortalEformHrVacationAfterComponent implements OnInit {
    @Input() doc: any = {};
    @Input() data: any = {};

    public rdata: any = { stat: {}, users: [], times: [] };

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        if (!this.data.type) this.data.type = 'time';
        if (!this.data.start) this.data.start = moment().format("YYYY-MM-DD");
        if (!this.data.end) this.data.end = moment().format("YYYY-MM-DD");
        if (!this.data.starttime) this.data.starttime = 9;

        if (!this.data.use) {
            if (this.data.used)
                this.data.use = this.data.used;
            else
                this.data.use = 8;
        }

        if (!this.data.alternative) this.data.alternative = '';

        await this.loadUsers();
        await this.timelist();
        await this.service.render();
    }

    public async change(target: string) {
        await this.timelist();
        await this.service.render();
    }

    public async loadUsers() {
        let { code, data } = await wiz.call("data", { doc_id: this.doc.id });
        this.rdata.users = data.users;
        this.rdata.stat = data.stat;
    }

    public async timelist() {
        if (this.data.type != 'time') return;
        let times = [];
        if (this.data.starttime < 12)
            for (let i = 1; i < 18 - this.data.starttime; i++)
                times.push(i);
        else
            for (let i = 1; i < 18 - this.data.starttime + 1; i++)
                times.push(i);
        this.rdata.times = times;
        // this.data.use = times[times.length - 1];
    }
}

export default PortalEformHrVacationAfterComponent;