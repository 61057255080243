import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.quickmenu');
import { OnInit } from '@angular/core';

@Component({
    selector: 'wiz-component-quickmenu',
template: templateSource || '',
    styles: [`

/* file: /opt/service/portal/branch/main/build/src/app/component.quickmenu/view.scss */
*,
*:before,
*:after {
  box-sizing: border-box;
}

.box {
  background: #f9f9f9;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.2), 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  margin-bottom: 20px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: none;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav a {
  min-width: 96px;
  text-decoration: none;
  color: var(--wc-gray-hover);
  text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.5);
  font-size: 16px;
  text-align: center;
}
.nav a:hover, .nav a.active {
  color: var(--wc-blue);
}
.nav .quick-icon {
  font-size: 24px;
  margin-bottom: 5px;
  display: block;
  line-height: 1;
}
.nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
}
.nav li {
  display: inline-block;
}
.nav li a {
  display: block;
  padding: 20px 18px;
}`],
})
export class ComponentQuickmenuComponent implements OnInit {
    public async ngOnInit() {
    }

    public menuActive(url: string) {
        let path = location.pathname;
        if (path.indexOf(url) == 0) return 'active';
        return '';
    }
}

export default ComponentQuickmenuComponent;