import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.admin.eform');
import { OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Service } from "src/libs/portal/season/service";
import { KeyMod, KeyCode } from 'monaco-editor';

import toastr from "toastr";
toastr.options = {
    "closeButton": false,
    "debug": false,
    "newestOnTop": true,
    "progressBar": false,
    "positionClass": "toast-top-center",
    "preventDuplicates": true,
    "onclick": null,
    "showDuration": 300,
    "hideDuration": 500,
    "timeOut": 1500,
    "extendedTimeOut": 1000,
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
};

@Component({
    selector: 'wiz-page-admin-eform',
template: templateSource || '',
    styles: [`

/* file: /opt/service/portal/branch/main/build/src/app/page.admin.eform/view.scss */
.wiz-content-page {
  flex-direction: row;
}
.wiz-content-page .eform-list {
  width: 280px;
  height: 100%;
  background-color: #fff;
  border-right: 1px solid var(--wc-border);
  border-left: 1px solid var(--wc-border);
  display: flex;
  flex-direction: column;
}
.wiz-content-page .eform-list .eform-list-header {
  display: flex;
  height: 48px;
  background-color: var(--wc-blue);
  border-bottom: 1px solid var(--wc-border);
  align-items: center;
  padding: 0 12px;
  line-height: 1;
}
.wiz-content-page .eform-list .eform-list-header .eform-list-header-title {
  color: white;
  font-family: "main-eb";
}
.wiz-content-page .eform-list .eform-list-content {
  flex: 1;
  overflow: auto;
}
.wiz-content-page .eform-list .eform-list-content .eform-list-item {
  border-bottom: 1px solid var(--wc-border);
  line-height: 1;
  padding: 12px 16px;
  cursor: pointer;
}
.wiz-content-page .eform-list .eform-list-content .eform-list-item:hover, .wiz-content-page .eform-list .eform-list-content .eform-list-item.active {
  background-color: rgba(108, 141, 246, 0.2);
}
.wiz-content-page .eform-list .eform-list-content .eform-list-item .eform-list-item-title {
  font-size: 16px;
}
.wiz-content-page .eform-list .eform-list-content .eform-list-item .text-muted {
  font-size: 12px;
}
.wiz-content-page .eform-content {
  flex: 1;
  overflow: auto;
  display: flex;
}
.wiz-content-page .eform-content .eform-editor {
  min-width: 860px;
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  border-right: 1px solid var(--wc-border);
  opacity: 0.5;
}
.wiz-content-page .eform-content .eform-editor:last-child {
  border-right: none;
}
.wiz-content-page .eform-content .eform-editor.active {
  opacity: 1;
}
.wiz-content-page .eform-content .eform-editor .eform-editor-header {
  font-family: "main-eb";
  line-height: 1;
  display: flex;
  background-color: var(--wc-blue);
  color: #fff;
}
.wiz-content-page .eform-content .eform-editor .eform-editor-header .eform-editor-title {
  padding: 4px 8px;
}
.wiz-content-page .eform-content .eform-editor .eform-editor-header .eform-editor-btn {
  padding: 4px 8px;
  cursor: pointer;
}
.wiz-content-page .eform-content .eform-editor .eform-editor-header .eform-editor-btn:hover {
  background-color: #000;
}
.wiz-content-page .eform-content .eform-editor .eform-editor-subheader {
  font-family: "main-eb";
  display: flex;
  background-color: var(--wc-blue);
  border-bottom: 1px solid var(--wc-border);
  line-height: 1;
}
.wiz-content-page .eform-content .eform-editor .eform-editor-subheader .eform-editor-tab {
  cursor: pointer;
  color: #fff;
  padding: 5px 12px;
  padding-top: 6px;
  line-height: 1;
  font-size: 12px;
}
.wiz-content-page .eform-content .eform-editor .eform-editor-subheader .eform-editor-tab.active {
  background-color: #fff;
  color: var(--wc-blue);
}
.wiz-content-page .eform-content .eform-editor .eform-editor-subheader .eform-editor-tab:hover {
  background-color: #000;
}
.wiz-content-page .eform-content .eform-editor .eform-editor-body {
  flex: 1;
  overflow: auto;
  position: relative;
}
.wiz-content-page .eform-content .eform-editor .eform-editor-body .editor-submenu {
  position: absolute;
  bottom: 16px;
  left: 0;
  width: 100%;
  text-align: center;
}

.editor-monaco {
  position: absolute;
  width: 100%;
  height: 100%;
}

.container {
  max-width: 860px;
}

.card-body {
  border: none;
}

.editor-preview {
  height: 100%;
  width: 100%;
}

.editor-info table td,
.editor-info table th {
  white-space: nowrap;
}

iframe {
  width: 100%;
  height: 100%;
}`],
})
export class PageAdminEformComponent implements OnInit {
    constructor(@Inject( Service) public service: Service,@Inject( DomSanitizer)  private sanitizer: DomSanitizer) { }

    public shortcuts: any = [];
    public dmap: any = [];
    public items: any = [];
    public tabs: any = [];
    public isrender: boolean = false;
    public activeTab: any = null;

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow('admin', "/");

        this.shortcuts = [
            {
                name: "save",
                key: ["cmd + s", "ctrl + s"],
                monaco: KeyMod.CtrlCmd | KeyCode.KeyS,
                preventDefault: true,
                command: async () => {
                    if (this.activeTab)
                        await this.save(this.activeTab);
                }
            },
            {
                name: "close",
                key: ["alt + w"],
                monaco: KeyMod.Alt | KeyCode.KeyW,
                preventDefault: true,
                command: async () => {
                    if (this.activeTab)
                        this.activeTab.close();
                }
            }
        ]

        await this.load();
        await this.approval.init();
    }

    public async alert(message: string, status: string = 'error', cancel: any = false, action: string = '확인') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public async load() {
        const { data } = await wiz.call("eforms");
        this.items = data;
        await this.service.render();
    }

    public safeUrl(url: string) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    public getUIBinding(namespace: string) {
        if (namespace) {
            return namespace.replace(/\./gim, '-')
        }
        return ""
    }

    public async editorInit(e) {
        for (let i = 0; i < this.shortcuts.length; i++) {
            let shortcut = this.shortcuts[i];
            e.editor.addCommand(shortcut.monaco, shortcut.command);
        }
    }

    public async render() {
        this.isrender = false;
        await this.service.render();
        this.isrender = true;
        await this.service.render();
    }

    public async build() {
        await this.service.loading.show();
        await wiz.call("build");
        await this.service.request.post("/wiz/ide/api/portal.app.modules/build");
        await this.service.loading.hide();
        toastr.info("Build Finish");
    }

    public async remove(tab: any) {
        let isdelete = await this.alert("'" + tab.data.title + "' 양식 삭제", 'error', '닫기', '삭제');
        if (!isdelete) return;
        let res = await wiz.call("remove", { id: tab.data.id });
        if (res.code == 200) {
            this.items.remove(tab.data);
            for (let _tab of this.tabs) {
                if (_tab.data.id == tab.data.id) {
                    await _tab.close();
                }
            }
            await this.load();
            return;
        }

        toastr.error("Error");
    }

    public async save(tab: any) {
        let data = JSON.stringify(tab.data);
        let res = await wiz.call("save", { data: data });
        if (res.code == 200) {
            await this.load();
            if (!tab.data.id) {
                await tab.close();
            }
            toastr.success("Updated");
            return;
        }

        await this.alert(res.data);
    }

    public async create() {
        let item: any = {
            namespace: "",
            short: "",
            category: "",
            title: "",
            status: "edit",
            trigger: {},
            extra: {}
        };

        await this.open(item);
    }

    public async open(obj: any) {
        let item = null;
        if (obj.id) {
            if (this.dmap[obj.id]) {
                item = this.dmap[obj.id];
            } else {
                const { code, data } = await wiz.call("get", { id: obj.id });
                if (code != 200) return;
                item = data;
                this.dmap[obj.id] = item;
            }
        } else {
            item = obj;
        }

        let tab = ((item: any) => {
            let obj: any = {};

            let monacoopt: any = (language: string) => {
                return {
                    language: language,
                    renderValidationDecorations: language == 'typescript' ? 'off' : 'on',
                    theme: "vs",
                    fontSize: 14,
                    automaticLayout: true,
                    scrollBeyondLastLine: true,
                    wordWrap: true,
                    roundedSelection: false,
                    glyphMargin: false,
                    minimap: {
                        enabled: false
                    }
                }
            }

            obj.data = item;
            obj.src = 'info';
            obj.view = 'info';
            obj.preview = null;

            obj.current = {};

            obj.active = async () => {
                this.activeTab = obj;
                await this.service.render();
            }

            obj.select = async (src: any) => {
                obj.src = src;

                if (src == 'info') {
                    await obj.setView("info");
                } else if (src == 'code') {
                    await obj.setView("pug");
                } else if (src == 'trigger') {
                    await obj.setView("trigger.init");
                } else if (src == 'preview') {
                    await obj.setView("preview");
                    obj.preview = this.safeUrl('/admin/eform/viewer/' + obj.data.namespace);
                }
                await this.render();
            }

            obj.setView = async (view: string) => {
                obj.view = view;

                if (view == 'pug') {
                    obj.current = {
                        monaco: monacoopt('pug'),
                        data: obj.data.extra,
                        target: 'pug'
                    }
                } else if (view == 'component') {
                    obj.current = {
                        monaco: monacoopt('typescript'),
                        data: obj.data.extra,
                        target: 'component'
                    }
                } else if (view == 'scss') {
                    obj.current = {
                        monaco: monacoopt('scss'),
                        data: obj.data.extra,
                        target: 'scss'
                    }
                } else if (view == 'api') {
                    obj.current = {
                        monaco: monacoopt('python'),
                        data: obj.data.extra,
                        target: 'api'
                    }
                } else if (view == 'trigger.init') {
                    obj.current = {
                        monaco: monacoopt('python'),
                        data: obj.data.trigger,
                        target: 'init'
                    }
                } else if (view == 'trigger.update') {
                    obj.current = {
                        monaco: monacoopt('python'),
                        data: obj.data.trigger,
                        target: 'update'
                    }
                } else if (view == 'trigger.submit') {
                    obj.current = {
                        monaco: monacoopt('python'),
                        data: obj.data.trigger,
                        target: 'submit'
                    }
                } else if (view == 'trigger.response') {
                    obj.current = {
                        monaco: monacoopt('python'),
                        data: obj.data.trigger,
                        target: 'response'
                    }
                } else if (view == 'trigger.finish') {
                    obj.current = {
                        monaco: monacoopt('python'),
                        data: obj.data.trigger,
                        target: 'finish'
                    }
                }

                await this.render();
            }

            obj.close = async () => {
                this.tabs.remove(obj);
                this.activeTab = null;
                await this.render();

                for (let _tab of this.tabs) {
                    if (_tab.data.id == obj.data.id) {
                        return;
                    }
                }

                delete this.dmap[obj.data.id];
            }

            obj.is = (item) => {
                return item.id == obj.data.id;
            }

            return obj;
        })(item);

        this.tabs.push(tab);
        await tab.active();
        await this.render();
    }

    public approval: any = (() => {
        let obj = {};

        obj.candidates = [];

        obj.init = async () => {
            obj.candidates.push({ type: 'macro', id: 'upper', name: '차상위 결재권자' });
            obj.candidates.push({ type: 'macro', id: 'teams', name: '팀원' });

            let { data } = await wiz.call("users");

            for (let user of data) {
                obj.candidates.push({ type: 'user', ...user });
            }
        }

        obj.addApprover = async (tab: any, item: any) => {
            if (!tab.data.extra.approver) tab.data.extra.approver = [];
            tab.data.extra.approver.push(JSON.parse(JSON.stringify(item)));
            this.service.render();
        }

        obj.addViewer = async (tab: any, item: any) => {
            if (!tab.data.extra.viewer) tab.data.extra.viewer = [];
            tab.data.extra.viewer.push(JSON.parse(JSON.stringify(item)));
            this.service.render();
        }

        return obj;
    })()
}

export default PageAdminEformComponent;