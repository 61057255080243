import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.eform.renderer');
import { Input } from '@angular/core';

@Component({
    selector: 'wiz-portal-eform-renderer',
template: templateSource || '',
    styles: [`

/* file: /opt/service/portal/branch/main/build/src/app/portal.eform.renderer/view.scss */
/* src/app/portal.eform.renderer/view.scss: no such file or directory */`],
})
export class PortalEformRendererComponent {
    @Input() doc: any;
    @Input() target: any;
}

export default PortalEformRendererComponent;