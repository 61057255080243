import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.adimin.user');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-adimin-user',
template: templateSource || '',
    styles: [`

/* file: /opt/service/portal/branch/main/build/src/app/page.adimin.user/view.scss */
.container-sm {
  max-width: 960px;
}
.container-sm .card {
  margin-bottom: 24px;
}
.container-sm .card .card-btn {
  border-top: none;
  border-bottom-left-radius: 16px;
}

.progress {
  background-color: transparent !important;
  border-radius: 0;
  position: relative;
  height: 8px;
}
.progress .progress-bar {
  background-color: var(--wc-blue-light-3);
  z-index: 999;
}
.progress .progress-divider-box {
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-top: 1px solid var(--wc-border);
  border-bottom: 1px solid var(--wc-border);
}
.progress .progress-divider-box .progress-divider {
  height: 100%;
  flex: 1;
  border-right: 1px solid var(--wc-light-3);
  z-index: 1000;
}
.progress .progress-divider-box .progress-divider:last-child {
  border-right: none;
}`],
})
export class PageAdiminUserComponent implements OnInit {
    public users: any = [];

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/auth/login");
        await this.loadUserList();
    }

    public async loadUserList() {
        await this.service.loading.show();
        const { data } = await wiz.call("users");
        this.users = data;
        await this.service.loading.hide();
        await this.service.render();
    }
}

export default PageAdiminUserComponent;