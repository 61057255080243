import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.widget.chart.server.env');
import { OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { ElementRef, ViewChild } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import ApexCharts from 'apexcharts';

@Component({
    selector: 'wiz-portal-widget-chart-server-env',
template: templateSource || '',
    styles: [``],
})
export class PortalWidgetChartServerEnvComponent implements OnInit {

    @Input() title: any = '';
    @Input() series: any = [];
    @Input() category: any = ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"];
    @Input() height: number = 180;
    @Input() animation: boolean = true;

    public trending: number = -1;
    public latest: number | null = null;
    public isRender: boolean = false;

    @ViewChild('chart')
    public element: ElementRef;

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.load();
        await this.render();
    }

    public async reload() {
        await this.load();
        await this.render();
    }

    public async load() {
        const { code, data } = await wiz.call("load");

        this.series = [{
            name: '온도',
            data: data.temperature
        }];

        this.trending = data.temperature[data.temperature.length - 1] - data.temperature[data.temperature.length - 13];
        this.trending = Math.round(this.trending * 10) / 10;
        this.latest = data.temperature[data.temperature.length - 1];
        this.latest_date = data.timestamp[data.timestamp.length - 1];
        this.category = data.timestamp;
    }

    public async render() {
        this.isRender = false;
        await this.service.render();

        let options = {
            series: this.series,
            chart: {
                height: this.height,
                type: 'area',
                sparkline: {
                    enabled: true
                },
                toolbar: {
                    show: false
                },
                animation: {
                    enabled: this.animation,
                    dynamicAnimation: {
                        enabled: this.animation
                    }
                }
            },
            grid: {
                padding: {
                    right: 0,
                    left: 0
                }
            },
            legend: {
                show: false
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth',
                width: 1
            },
            xaxis: {
                type: 'datetime',
                categories: this.category,
                labels: {
                    datetimeUTC: false,
                    show: false,
                }
            },
            tooltip: {
                enabled: this.animation,
                x: {
                    format: 'yyyy/MM/dd HH:mm'
                },
                y: {
                    formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                        return value + " °C";
                    }
                }
            }
        };

        if (this.title) {
            options.title = {
                text: this.title,
                offsetX: 24,
                style: {
                    fontSize: '18px',
                    cssClass: 'apexcharts-yaxis-title'
                }
            };
        }

        this.isRender = true;
        await this.service.render();

        let chart = new ApexCharts(this.element.nativeElement, options);
        chart.render();
    }
}

export default PortalWidgetChartServerEnvComponent;