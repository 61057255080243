import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.eform.main');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-eform-main',
template: templateSource || '',
    styles: [`

/* file: /opt/service/portal/branch/main/build/src/app/page.eform.main/view.scss */
.container {
  max-width: 960px;
}

.form-item {
  cursor: pointer;
}
.form-item:hover {
  background: var(--wc-blue-light);
}`],
})
export class PageEformMainComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public items: any = [];
    public keyword: string = '';

    public categorymap: any = {
        HR: '인사',
        GA: '총무',
        FA: '재무',
        WR: '업무'
    }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/auth/login");
        await this.load();
    }

    public async alert(message: string, status: string = 'error', cancel: any = false, action: string = '확인') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public async load() {
        const { data } = await wiz.call("eforms");
        this.items = data;
        await this.search("");
        await this.service.render();
    }

    public async search(keyword: string) {
        for (let i = 0; i < this.items.length; i++) {
            this.items[i].show = false;
            if (this.items[i].title.indexOf(keyword) >= 0)
                this.items[i].show = true;
        }
        await this.service.render();
    }

    public async create(form: any, force: any = false) {
        const { code, data } = await wiz.call("create", { form, force });
        if (code != 200) {
            if (code == 301) {
                let res = await this.alert('작성중인 문서가 있습니다.', 'warning', '새로작성', '이어서작성');
                if (res === true) {
                    this.service.href("/eform/docs/" + data);
                } else if (res === false) {
                    await this.create(form, true);
                }
                return;
            }
            return;
        }
        this.service.href("/eform/docs/" + data);
    }
}

export default PageEformMainComponent;