import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.util.memo');
import { OnInit } from '@angular/core';
import { ElementRef, ViewChild } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import ClassicEditor from "src/libs/ckeditor/ckeditor";
import moment from 'moment';
import $ from 'jquery';

@Component({
    selector: 'wiz-portal-util-memo',
template: templateSource || '',
    styles: [`

/* file: /opt/service/portal/branch/main/build/src/app/portal.util.memo/view.scss */
.container {
  height: 100%;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
}
.container .card {
  height: 100%;
  overflow: hidden;
  border-radius: 0;
}
.container .card .card-body {
  height: 100%;
  overflow: hidden;
  border-radius: 0;
}

.sidebar {
  display: flex;
  height: 100%;
  overflow: hidden;
  width: 260px;
  border-right: 1px solid #ccc;
  position: relative;
  z-index: 100;
  flex-direction: column;
}
.sidebar .sidebar-header {
  padding: 16px;
  border-bottom: 1px solid #ccc;
}
.sidebar .sidebar-footer {
  width: 260px;
  position: absolute;
  bottom: 0;
}
.sidebar .sidebar-content {
  flex: 1;
  overflow: hidden;
}
.sidebar .sidebar-content .sidebar-content-scroll {
  height: 100%;
  overflow: auto;
  padding-bottom: 120px;
}
.sidebar .sidebar-content td {
  padding: 12px;
  cursor: pointer;
}
.sidebar .sidebar-content h4 {
  font-size: 15px;
}
.sidebar .sidebar-content span.text-muted {
  font-size: 12px;
  padding: 0;
}
.sidebar .sidebar-content .active {
  background: var(--wc-blue-light);
  color: #354052;
}
.sidebar .sidebar-search {
  border-bottom: 1px solid var(--wc-border);
}
.sidebar .sidebar-search .form-control {
  border-radius: 0;
  border: none;
  outline: none;
}
.sidebar .sidebar-search .form-control:focus {
  outline: none;
  box-shadow: none;
}
.sidebar .sidebar-search .btn {
  border: none;
}

.context-area {
  position: absolute;
  top: 0;
  left: 0;
  padding-left: 260px;
  height: 100%;
  width: 100%;
}
.context-area .tox-tinymce {
  border: none;
}

.fab-area {
  position: absolute;
  bottom: 24px;
  right: 24px;
  z-index: 9999;
}
.fab-area .btn {
  border-radius: 50%;
  width: 56px;
  height: 56px;
}
.fab-area .btn i {
  font-size: 18px;
}

@media screen and (max-width: 768px) {
  .container {
    height: 100%;
  }
  .sidebar,
  .context-area {
    width: 100%;
    display: block;
    position: relative;
    padding: 0;
  }
  .sidebar {
    display: none;
  }
  .ck-editor__editable_inline {
    height: calc(100vh - 156px);
  }
  .sidebar {
    height: auto;
  }
  .sidebar .sidebar-footer {
    width: 100%;
    position: relative;
  }
  .fab-area {
    position: fixed;
  }
}
table tr:first-child td {
  border-top: none;
}`],
})
export class PortalUtilMemoComponent implements OnInit {

    @ViewChild('editor')
    public editorElement: ElementRef;

    public editor: any;
    public editorLoaded: boolean = false;

    public list: any = [];
    public selected: any = {};

    public shortcuts: any = [];
    public query: any = {
        text: ''
    };

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/auth/login");
        await this.buildEditor();
        await this.load();

        this.shortcuts = [
            {
                name: "save",
                key: ["cmd + s", "ctrl + s"],
                preventDefault: true,
                command: async () => {
                    await this.update();
                }
            }
        ]
    }

    public async alert(title: string, message: string, action: string = '확인', cancel: any = '닫기') {
        return await this.service.alert.show({
            title: title,
            message: message,
            cancel: cancel,
            actionBtn: 'error',
            action: action,
            status: 'error'
        });
    }

    public async buildEditor() {
        this.editorLoaded = false;
        await this.service.render();

        this.editorLoaded = true;
        await this.service.render();

        let editor = this.editor = await ClassicEditor.create(this.editorElement.nativeElement, {
            toolbar: {
                shouldNotGroupWhenFull: true
            },
            removePlugins: ["MediaEmbedToolbar", "Markdown"],
            table: ClassicEditor.defaultConfig.table,
            simpleUpload: {
                uploadUrl: '/file/memo/upload/' + this.selected.id
            }
        });

        editor.keystrokes.set('Ctrl+S', async (event, cancel) => {
            event.preventDefault();
            await this.update();
        }, { priority: 'high' });
    }

    public async load(refresh: boolean = true) {
        const { data } = await wiz.call("search", this.query);
        this.list = data;

        if (this.selected && !this.selected.id)
            refresh = true;

        if (refresh) {
            if (this.list.length > 0)
                await this.select(this.list[0]);
            else
                await this.create();
        }
        await this.service.render();
    }

    public async select(item) {
        const { data } = await wiz.call("read", item);
        await this.buildEditor();
        this.selected = data;
        this.editor.data.set(this.selected.content);
        await this.service.render();
    }

    public async create() {
        this.selected = {
            title: "새로운 메모"
        };
        this.editor.data.set("<h3>새로운메모</h3>");
        await this.service.render();
    }

    public async update() {
        let item = JSON.parse(JSON.stringify(this.selected));
        item.content = this.editor.data.get();

        if (item.content.length == 0) {
            await this.delete();
            return;
        }

        let items = $(item.content);
        item.title = "";
        for (var i = 0; i < items.length; i++) {
            item.title = item.title + $(items[i]).text().trim();
            if (item.title.length > 0) break;
        }
        if (item.title.length > 48) item.title = item.title.substring(0, 48);
        if (item.title.length == 0) item.title = '제목없음';

        const { code } = await wiz.call("update", item);
        if (code == 200) {
            this.service.toast.success('저장되었습니다');
        } else {
            await this.alert("Error", "저장중 오류가 발생했습니다", false, '닫기');
        }

        await this.load(false);
    }

    public async delete() {
        let res = await this.alert('메모 삭제', '삭제하면 복원할수 없습니다. 정말 삭제하시겠습니까?', '삭제', '취소');
        if (!res) return;

        await wiz.call("delete", { id: this.selected.id });
        await this.load();
    }

    public displayDate(date) {
        let targetdate = moment(date);
        let diff = new Date().getTime() - new Date(targetdate).getTime();
        diff = diff / 1000 / 60 / 60;
        if (diff > 24) return targetdate.format("YYYY-MM-DD");
        if (diff > 1) return Math.floor(diff) + "시간전"
        diff = diff * 60;
        if (diff < 2) return "방금전";
        return Math.floor(diff) + "분전";
    }
}

export default PortalUtilMemoComponent;