import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.admin.eform.viewer');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-admin-eform-viewer',
template: templateSource || '',
    styles: [`

/* file: /opt/service/portal/branch/main/build/src/app/page.admin.eform.viewer/view.scss */
.container {
  max-width: 860px;
}

.card-body {
  border: none;
}

.alert {
  font-size: 16px;
}`],
})
export class PageAdminEformViewerComponent implements OnInit {
    public target: any;
    public form: any = {};
    public doc: any = null;

    constructor(@Inject( Service) public service: Service) {
        this.target = WizRoute.segment.id;
    }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow('admin', "/");
        await this.load();
    }

    public async load() {
        const { data } = await wiz.call("data", { 'namespace': this.target });
        this.form = data;
        this.doc = { data: {}, visibility: data.visibility };
        await this.service.render();
    }
}

export default PageAdminEformViewerComponent;