import { Inject } from '@angular/core';
import templateSource from './app.component.html';
              import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz');
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'app-root',
template: templateSource || '',
    styles: [``],
})
export class AppComponent implements OnInit {
    constructor(@Inject( Service)         public service: Service,@Inject( Router)         public router: Router,@Inject( ChangeDetectorRef    )         public ref: ChangeDetectorRef    ) { }

    public async ngOnInit() {
        await this.service.init(this);
    }
}