import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('layout.layout.aside.full');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-layout-layout-aside-full',
template: templateSource || '',
    styles: [`

/* file: /opt/service/portal/branch/main/build/src/app/layout.layout.aside.full/view.scss */
.wiz-page {
  flex-direction: row;
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 100%;
  overflow: hidden;
}
@media (max-width: 768px) {
  .wiz-page {
    flex-direction: column;
  }
}

.wiz-nav {
  max-height: 100%;
  overflow: hidden;
}

.loading-zone {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999999;
}
.loading-zone .loading-zone-container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
}`],
})
export class LayoutLayoutAsideFullComponent implements OnInit {
    constructor(@Inject( Service    )         public service: Service    ) { }

    public async ngOnInit() {
        await this.service.init();
    }
}

export default LayoutLayoutAsideFullComponent;