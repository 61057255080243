import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.eform.docs');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-eform-docs',
template: templateSource || '',
    styles: [`

/* file: /opt/service/portal/branch/main/build/src/app/page.eform.docs/view.scss */
.container {
  max-width: 960px;
}

.form-item {
  cursor: pointer;
}
.form-item:hover {
  background: var(--wc-blue-light);
}

table th, table td {
  white-space: nowrap;
}`],
})
export class PageEformDocsComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public loaded: boolean = false;
    public page: number = 1;
    public startpage: number = 1;
    public lastpage: number = 1;
    public data: any = [];

    public categorymap: any = {
        HR: '인사',
        GA: '총무',
        FA: '재무',
        WR: '업무'
    }

    public statusmap: any = {
        draft: '작성중',
        process: '결재중',
        accept: '승인됨',
        reject: '반려됨',
        cancel: '회수됨'
    };

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/auth/login");
        await this.load();
    }

    public async load(page: number = 1) {
        this.loaded = false;
        await this.service.render();

        const { code, data } = await wiz.call("docs", { page });
        if (code != 200 || !data) return;

        const { docs, lastpage } = data;
        const startpage = Math.floor((page - 1) / 10) * 10 + 1;

        this.page = page;
        this.lastpage = lastpage;
        this.startpage = startpage;
        this.data = docs;

        this.loaded = true;
        await this.service.render();
    }

    public async pageMove(page: number) {
        await this.load(page);
    }
}

export default PageEformDocsComponent;