import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.eform.viewer');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-eform-viewer',
template: templateSource || '',
    styles: [`

/* file: /opt/service/portal/branch/main/build/src/app/component.eform.viewer/view.scss */
.container {
  max-width: 860px;
}

.card-body {
  border: none;
}

.alert {
  font-size: 16px;
}`],
})
export class ComponentEformViewerComponent implements OnInit {
    @Input() doc_id: any;
    @Input() parentUrl: any;

    public response: any = {};
    public form: any = {};
    public signs: any = [];
    public doc: any = null;
    public role: string = 'viewer';

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.load();
    }

    public async alert(message: string, status: any = "error") {
        return await this.service.alert.show({
            title: '',
            message: message,
            cancel: false,
            actionBtn: status,
            action: "확인",
            status: status
        });
    }

    public async load() {
        const { code, data } = await wiz.call("data", { 'doc_id': this.doc_id });
        if (!data || code != 200) {
            this.service.href("/eform/main");
            return;
        }

        this.doc = data.doc;
        this.form = data.form;
        this.user = data.user;
        this.role = data.role;
        this.signs = data.signs;

        if (!this.doc)
            this.service.href("/eform/main");

        await this.service.render();
    }

    public async delete() {
        let res = await this.service.alert.show({
            title: "문서삭제",
            message: "정말로 삭제하시겠습니까?",
            cancel: "취소",
            actionBtn: "warning",
            action: "삭제",
            status: "warning"
        });
        if (!res) return;
        await wiz.call("delete", { 'doc_id': this.doc_id });
        this.service.href("/eform/main");
    }

    public async update() {
        let { code, data } = await wiz.call("update", { 'doc_id': this.doc_id, 'data': JSON.stringify(this.doc.data), 'visibility': this.doc.visibility });
        if (code == 200) {
            await this.alert("저장되었습니다", "success");
        } else {
            await this.alert(data);
        }
    }

    public async submit() {
        let res = await this.service.alert.show({
            title: "",
            message: "문서를 제출하시겠습니까?",
            cancel: "취소",
            actionBtn: "success",
            action: "제출",
            status: "success"
        });

        if (!res) return;

        res = await wiz.call("update", { 'doc_id': this.doc_id, 'data': JSON.stringify(this.doc.data), 'visibility': this.doc.visibility });
        if (res.code != 200) return await this.alert(res.data);
        res = await wiz.call("submit", { 'doc_id': this.doc_id });
        if (res.code != 200) return await this.alert(res.data);

        await this.service.loading.show();
        location.reload();
    }

    public async cancel() {
        let res = await this.service.alert.show({
            title: "",
            message: "문서를 회수하시겠습니까?",
            cancel: "취소",
            actionBtn: "error",
            action: "회수",
            status: "error"
        });

        if (!res) return;

        res = await wiz.call("cancel", { 'doc_id': this.doc_id });
        if (res.code != 200) return await this.alert(res.data);

        await this.service.loading.show();
        location.reload();
    }

    public async reject() {
        let res = await this.service.alert.show({
            title: "",
            message: "문서를 반려하시겠습니까?",
            cancel: "취소",
            actionBtn: "error",
            action: "반려",
            status: "error"
        });

        if (!res) return;

        res = await wiz.call("reject", { 'doc_id': this.doc_id, 'response': JSON.stringify(this.response) });
        if (res.code != 200) return await this.alert(res.data);

        await this.service.loading.show();
        location.reload();
    }

    public async accept() {
        let res = await this.service.alert.show({
            title: "",
            message: "문서를 승인하시겠습니까?",
            cancel: "취소",
            actionBtn: "success",
            action: "승인",
            status: "success"
        });

        if (!res) return;

        res = await wiz.call("accept", { 'doc_id': this.doc_id, 'response': JSON.stringify(this.response) });
        if (res.code != 200) return await this.alert(res.data);

        await this.service.loading.show();
        location.reload();
    }

    public async upload() {
        let files = await this.service.file.select();
        let fd = new FormData();
        let filepath = [];
        for (let i = 0; i < files.length; i++) {
            if (!files[i].filepath) files[i].filepath = files[i].name;
            fd.append('file[]', files[i]);
            filepath.push(files[i].filepath);
        }
        fd.append("filepath", JSON.stringify(filepath));
        fd.append("doc_id", this.doc_id);

        let url = wiz.url('upload');
        let { code, data } = await this.service.file.upload(url, fd);
        if (code != 200) {
            await this.alert("파일 업로드 중 오류가 발생했습니다")
            return;
        }

        if (this.doc.status == 'draft') {
            if (!this.doc.data.attachment)
                this.doc.data.attachment = [];
            for (let i = 0; i < data.length; i++)
                this.doc.data.attachment.push(data[i]);
        } else {
            if (!this.response.attachment)
                this.response.attachment = [];
            for (let i = 0; i < data.length; i++)
                this.response.attachment.push(data[i]);
        }

        await this.service.render();
    }

    public async removeFile(items: any, file: any) {
        items.remove(file);
        await this.service.render();
    }
}

export default ComponentEformViewerComponent;