import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.widget.card');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-widget-card',
template: templateSource || '',
    styles: [`

/* file: /opt/service/portal/branch/main/build/src/app/portal.widget.card/view.scss */
.scard {
  position: relative;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  padding: 1rem;
  width: 100%;
  height: 100%;
  text-align: center;
  color: whitesmoke;
  background-color: whitesmoke;
  border: var(--tblr-card-border-width) solid var(--tblr-card-border-color);
  border-radius: 16px;
}
.scard:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 110%;
  background-size: cover;
  background-position: 0 0;
  transition: transform calc(var(--d) * 1.5) var(--e);
  pointer-events: none;
}
.scard:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 200%;
  pointer-events: none;
  background-image: linear-gradient(to bottom, hsla(0, 0%, 0%, 0) 0%, hsla(0, 0%, 0%, 0.009) 11.7%, hsla(0, 0%, 0%, 0.034) 22.1%, hsla(0, 0%, 0%, 0.072) 31.2%, hsla(0, 0%, 0%, 0.123) 39.4%, hsla(0, 0%, 0%, 0.182) 46.6%, hsla(0, 0%, 0%, 0.249) 53.1%, hsla(0, 0%, 0%, 0.32) 58.9%, hsla(0, 0%, 0%, 0.394) 64.3%, hsla(0, 0%, 0%, 0.468) 69.3%, hsla(0, 0%, 0%, 0.54) 74.1%, hsla(0, 0%, 0%, 0.607) 78.8%, hsla(0, 0%, 0%, 0.668) 83.6%, hsla(0, 0%, 0%, 0.721) 88.7%, hsla(0, 0%, 0%, 0.762) 94.1%, hsla(0, 0%, 0%, 0.79) 100%);
  transform: translateY(-50%);
  transition: transform calc(var(--d) * 2) var(--e);
}
.scard:before {
  background-image: var(--featured-image);
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 1rem;
  transition: transform var(--d) var(--e);
  z-index: 1;
}
.content > * + * {
  margin-top: 1rem;
}

.title {
  font-size: 1.3rem;
  font-weight: 1000;
  line-height: 1.2;
  text-shadow: 2px 4px 3px #000;
}

.copy {
  font-family: var(--font-serif);
  font-size: 1.125rem;
  font-style: italic;
  line-height: 1.35;
}

.btn {
  cursor: pointer;
  margin-top: 1.5rem;
  padding: 0.75rem 1.5rem;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.025rem;
  text-transform: uppercase;
  color: white;
  background-color: black;
  border: none;
  border-radius: 8px;
}
.btn:hover {
  background-color: #0d0d0d;
}
.btn:focus {
  outline: 1px dashed yellow;
  outline-offset: 3px;
}

@media (hover: hover) and (min-width: 769px) {
  .scard:after {
    transform: translateY(0);
  }
  .content {
    transform: translateY(calc(100% - 6rem));
  }
  .content > *:not(.title) {
    opacity: 0;
    transform: translateY(1rem);
    transition: transform var(--d) var(--e), opacity var(--d) var(--e);
  }
  .scard:hover,
  .scard:focus-within {
    align-items: center;
  }
  .scard:hover:before,
  .scard:focus-within:before {
    transform: translateY(-4%);
  }
  .scard:hover:after,
  .scard:focus-within:after {
    transform: translateY(-50%);
  }
  .scard:hover .content,
  .scard:focus-within .content {
    transform: translateY(0);
  }
  .scard:hover .content > *:not(.title),
  .scard:focus-within .content > *:not(.title) {
    opacity: 1;
    transform: translateY(0);
    transition-delay: calc(var(--d) / 8);
  }
  .scard:focus-within:before, .scard:focus-within:after,
  .scard:focus-within .content,
  .scard:focus-within .content > *:not(.title) {
    transition-duration: 0s;
  }
}`],
})
export class PortalWidgetCardComponent implements OnInit {
    @Input() click: any;
    @Input() title: string = '';
    @Input() btn: string = '';
    @Input() desc: string = '';
    @Input() imageSrc: string = '';

    constructor(@Inject( Service    )         public service: Service    ) { }

    public async ngOnInit() {
        this.style = { 'background-image': 'url(' + this.imageSrc + ')' };
        await this.service.render();
    }
}

export default PortalWidgetCardComponent;